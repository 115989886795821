import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../css/payment_screen.css";
import { Dropdown } from "primereact/dropdown";
import PaymentStripModule from "./dialog/stripe_payment";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { ToastContainer, toast } from "react-toastify";
import { Checkbox } from "primereact/checkbox";
import Axios from "axios";
import { Tickbtn } from "../../../../../../../../utility";
import CardAuthPayment from "./dialog/CardAuthPayment";
import EcheckAuthPayment from "./dialog/EcheckAuthPayment";
const BASE_URL = process.env.REACT_APP_BASE_URL;

function capitalizeSentence(sentence) {
    // Split the sentence into words
    const words = sentence.split(" ");

    // Capitalize the first letter of each word and convert the rest to lowercase
    const capitalizedWords = words.map((word) => {
        // Convert the first letter to uppercase and the rest to lowercase
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words back into a sentence
    return capitalizedWords.join(" ");
}
const PaymentScreen = ({ setActiveIndex, activeIndex, _id }) => {
    const [authorizeEcheckPaymentDialog, setAuthorizeEcheckPaymentDialog] = useState(false);
    const parseLabelSubmissionVal = JSON.parse(localStorage.getItem("prepaidbasicData"));
    const labelSubmissionval = parseLabelSubmissionVal?.data?.labelSubmissionval;
    const comingfromincomplete = localStorage.getItem("comingfromincomplete");
    const [inventory, setInventory] = useState();
    const [authorizePaymentDialog, setAuthorizePaymentDialog] = useState(false);
    const [propectWithInvoice, setProspectWithInvoice] = useState(false);
    const [propectWithOutInvoice, setProspectWithOutInvoice] = useState(false);
    const [paymentmethoderror, setpaymentmethoderror] = useState(false);
    const [current, setCurrentSelect] = useState("");
    const [currentPlanSelect, setCurrentPlanSelect] = useState("");
    const [currentScreen, setCurrentScreen] = useState(1);
    const [previousRate, setPreviousRate] = useState(0);
    const [paymentDialogVisibility, setPaymentDialogVisibility] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ratesLoading, setRatesLoading] = useState(false);
    const [paidAmountRequired, setPaidAmountRequired] = useState(false);
    const [previousPlanPrice, setPreviousPlanPrice] = useState(0);
    const [expectedCost, setExpectedCost] = useState(false);
    const [carrier, setCarrier] = useState([]);
    const [carrierVal, setCarrierVal] = useState("");
    const [services, setServices] = useState([]);
    const [servicesVal, setServicesVal] = useState("");
    const [rates, setRates] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [deliveryVal, setDeliveryVal] = useState("");
    const [checked, setChecked] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [activeWithInvoice, setActiveWithInvoice] = useState(false);
    const [activeWithoutInvoice, setActiveWithoutInvoice] = useState(false);
    localStorage.setItem("generatelabel", generate);
    // localStorage.setItem("checkLabel", checked);
    JSON.stringify(localStorage.setItem("checkLabel", checked));
    const [checkLabelVal, setCheckLabelVal] = useState(comingfromincomplete ? labelSubmissionval : false);
    localStorage.setItem("labelCheck", checkLabelVal);
    const deliveryFedEx = [
        { name: "None", value: "none" },
        { name: "Delivery", value: "delivery" },
        { name: "Signature", value: "signature" },
        { name: "Direct_Signature", value: "direct_signature" },
        { name: "Adult_Signature", value: "adult_signature" },
    ];
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const companyName = parseLoginRes?.companyName;
    const toCapitalCompanyName = companyName.toUpperCase();
    let paymentInfo = JSON.parse(localStorage.getItem("paymentallinfo"))?.data;
    // let storedData = JSON.parse(localStorage.getItem("prepaidzipData"));

    let prepaidaddress = JSON.parse(localStorage.getItem("prepaidaddress"));
    const validationSchema = Yup.object().shape({
        billId: Yup.string().required("Product is required"),
        plan: Yup.string().required("Plan is required"),
    });

    useEffect(() => {
        if (carrierVal !== "") {
            const getServices = async () => {
                const response = await Axios.get(`${BASE_URL}/api/web/shipment/carrier/listServices?carrierCode=${carrierVal}`);
                const data = response?.data?.data;
                setServices(data);
            };
            if (carrierVal !== undefined) {
                getServices();
                // setDeliveryVal("");
            }
        }
    }, [carrierVal]);

    useEffect(() => {
        const delivery = async () => {
            const response = await Axios.get(`${BASE_URL}/api/web/shipment/carrier/getDeliveryConfirmation`);
            const data = response?.data;
            setDelivery(data);
        };
        delivery();
        const getCarrier = async () => {
            const response = await Axios.get(`${BASE_URL}/api/web/shipment/carrier/carriersList`);
            const data = response?.data?.data;

            setCarrier(data);
        };
        getCarrier();
        if (comingfromincomplete) {
            let prepaidcutomerdata = JSON.parse(localStorage.getItem("prepaidbasicData"));

            setChecked(prepaidcutomerdata?.data?.shippingLabelDiscount);
            setCheckLabelVal(prepaidcutomerdata?.data?.labelSubmissionval);
            setCarrierVal(prepaidcutomerdata?.data?.carrierVal);
            setServicesVal(prepaidcutomerdata?.data?.servicesVal);
            setDeliveryVal(prepaidcutomerdata?.data?.deliveryVal);
        }
    }, []);

    useEffect(() => {
        if (carrierVal !== "" && servicesVal !== "" && deliveryVal !== "") {
            setRatesLoading(true);
            const dataToSend = {
                serviceprovider: prepaidaddress?.data?.serviceProvider,
                carrierCode: carrierVal,
                serviceCode: servicesVal,
                // toPostalCode: prepaidaddress?.data?.zip,
                toPostalCode: prepaidaddress?.data?.isSameServiceAddress ? prepaidaddress?.data?.zip : prepaidaddress?.data?.isNotSameServiceAddress ? prepaidaddress?.data?.mailingZip : prepaidaddress?.data?.isPoBoxAddress ? prepaidaddress?.data?.poBoxZip : "",

                toCountry: "US",
                // toState: prepaidaddress?.data?.state,
                toState: prepaidaddress?.data ? prepaidaddress?.data?.state?.toUpperCase() : prepaidaddress?.data?.isNotSameServiceAddress ? prepaidaddress?.data?.mailingState?.toUpperCase() : prepaidaddress?.data?.isPoBoxAddress ? prepaidaddress?.data?.poBoxState?.toUpperCase() : "",
                // toCity: prepaidaddress?.data?.city,
                toCity: prepaidaddress?.data ? prepaidaddress?.data?.city?.toUpperCase() : prepaidaddress?.data?.isNotSameServiceAddress ? prepaidaddress?.data?.mailingCity?.toUpperCase() : prepaidaddress?.data?.isPoBoxAddress ? prepaidaddress?.data?.poBoxCity?.toUpperCase() : "",
                packageCode: "",
                confirmation: deliveryVal,
                residential: "false",
                inventoryType: localStorage.getItem("product"),
            };

            const getRates = async () => {
                if (checkLabelVal) {
                    try {
                        const response = await Axios.post(`${BASE_URL}/api/web/shipment/getRates`, dataToSend);
                        if (currentScreen === 3 && response?.data?.msg && response?.data?.data === undefined) {
                            toast.error(response.data.msg);
                            setRatesLoading(false);
                            setExpectedCost(false);
                            return;
                        }
                        const data = response?.data?.data;

                        if (Array.isArray(data) && data.length > 0) {
                            setRates(data);
                            setRatesLoading(false);
                            setExpectedCost(true);
                        } else {
                            setRatesLoading(false);
                        }
                    } catch (error) {
                  
                    }
                }
            };

            getRates();
        } else {
        }
    }, [carrierVal && servicesVal, deliveryVal, localStorage.getItem("product")]);
   const [firstincomplete,setfirstincomplete]=useState(false)
    const onPlanSelect = (item) => {
        setCurrentPlanSelect(item._id);
        if (formik.values.plan === "") {
            let devicepricing = JSON.parse(localStorage.getItem("planprices"));
            for (let i = 0; i < devicepricing.length; i++) {
                if (devicepricing[i]._id === item._id) {
                    formik.setFieldValue("totalamount", (parseFloat(formik.values.totalamount) + devicepricing[i].price).toString());
                    setPreviousPlanPrice(devicepricing[i].price);
                }
            }

            formik.setFieldValue("plan", item._id);
            //formik.handleChange(e);
        } else {
            let devicepricing = JSON.parse(localStorage.getItem("planprices"));

            for (let i = 0; i < devicepricing.length; i++) {
                if (devicepricing[i]._id === item._id) {
                    let currentamount = parseFloat(formik.values.totalamount);
                    let currentafterremovingprevious = currentamount - previousPlanPrice;
                    formik.setFieldValue("totalamount", (currentafterremovingprevious + devicepricing[i].price).toString());
                    setPreviousPlanPrice(devicepricing[i].price);
                }
            }
            formik.setFieldValue("plan", item._id);
            //formik.handleChange(e);
        }
    };       
    useEffect(()=>{      
        if(currentScreen === 3){ 
             if(comingfromincomplete){  
                      if(!firstincomplete){   
                        let prepaidcutomerdata=JSON.parse(localStorage.getItem("prepaidbasicData"))
        let cost = 0;
        rates.map((item) => {
            if (item?.serviceCode === servicesVal && item?.serviceName.includes("- Package")) {
                cost = item?.shipmentCost + item?.otherCost;
            }
        });         
          let currentamount=0;   
         if(formik.values.totalamount !== ""){
    currentamount = parseFloat(formik.values.totalamount); 
         }

            
         if(!prepaidcutomerdata?.data?.shippingLabelDiscount){
        currentamount += cost;   
         } 

         else{ 
            currentamount += cost ;     
         }
     

        formik.setFieldValue("totalamount", currentamount); 
            setPreviousRate(cost);  
        setfirstincomplete(true)
        }
    
             }
        }
    },[currentScreen])
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            plan: "",
            billId: "",
            paymentMode: "",
            discount: [],
            additional: [],
            totalamount: "",
            paid: "",
            customerid: _id,
            type: "Sign Up ",
            discounts: "",
            prospectwithinvoice: false,
            prospectwithoutinvoice: false,
        },
        onSubmit: async (values, actions) => {
            if (formik.values.prospectwithoutinvoice || formik.values.prospectwithinvoice) {
                localStorage.setItem("paymentscreendetails", JSON.stringify(formik.values));
                setActiveIndex(3);
            } else {
                if (formik.values.paymentMode === "Credit Card" || formik.values.paymentMode === "Credit Card (Authorize)") {
                    // if (toCapitalCompanyName.toUpperCase() === "ZISFONE LLC") {

                    localStorage.setItem("paymentscreendetails", JSON.stringify(formik.values));

                    if (localStorage.getItem("paymentstatus") === "paid") {
                        setActiveIndex(3);
                    } else {
                        if (formik.values.paymentMode === "Credit Card (Authorize)") {
                            setAuthorizePaymentDialog(true);
                        } else {
                            if (formik.values.paid !== "") {
                                setPaymentDialogVisibility(true);
                            } else {
                                paidAmountRequired(true);
                            }
                        }
                    }
                } else {
                    if (formik.values.paymentMode === "Skip Payment") {
                        let object = formik.values;
                        let paid = formik.values.paid;
                        localStorage.setItem("paymentscreendetails", JSON.stringify(formik.values));

                        localStorage.setItem("paymentstatus", "paid");

                        //setActiveIndex(3);
                        let paymentproceed = localStorage.getItem("paymentstatus");

                        if (paymentproceed === "paid") {
                            let additionalFeature = [];
                            let discounts = [];

                            let devicepricing = JSON.parse(localStorage.getItem("devicepricing"));
                            let simpricing = JSON.parse(localStorage.getItem("simpricing"));
                            let dueDate = "";
                            let applyLateFee = "";
                            let oneTimeCharge = "";
                            let planName = "";
                            let planId = "";
                            let planCharges = "";
                            if (object?.billId === simpricing?._id) {
                                dueDate = simpricing?.dueDate;
                                oneTimeCharge = simpricing?.oneTimeCharge;
                                applyLateFee = simpricing?.applyLateFee;
                                let simselecteddiscounts = JSON.parse(localStorage.getItem("simdiscountobjectarray"));

                                for (let i = 0; i < simpricing?.selectdiscount?.length; i++) {
                                    for (let k = 0; k < simselecteddiscounts.length; k++) {
                                        if (simselecteddiscounts[k] === simpricing?.selectdiscount[i]?._id) {
                                            let obj = {
                                                name: simpricing?.selectdiscount[i]?.discountname,
                                                amount: simpricing?.selectdiscount[i]?.amount,
                                            };
                                            discounts.push(obj);
                                        }
                                    }
                                }
                                let plandata = JSON.parse(localStorage.getItem("planprices"));
                                for (let i = 0; i < plandata?.length; i++) {
                                    if (object.plan === plandata[i]?._id) {
                                        planName = plandata[i]?.name;
                                        planCharges = plandata[i]?.price;

                                        planId = plandata[i]?._id;
                                    }
                                }

                                let simadditional = JSON.parse(localStorage.getItem("simadditionalfeaturearray"));
                                for (let k = 0; k < simadditional?.length; k++) {
                                    for (let i = 0; i < simpricing?.additionalFeature?.length; i++) {
                                        if (simpricing?.additionalFeature[i]?._id === simadditional[k]) {
                                            let obj = {
                                                name: simpricing?.additionalFeature[i]?.featureName,
                                                amount: simpricing?.additionalFeature[i]?.featureAmount,
                                            };
                                            additionalFeature.push(obj);
                                        }
                                    }
                                }
                            } else {
                                let plandata = JSON.parse(localStorage.getItem("planprices"));
                                dueDate = devicepricing?.dueDate;
                                applyLateFee = devicepricing?.applyLateFee;
                                oneTimeCharge = devicepricing?.oneTimeCharge;
                                let deviceselecteddiscounts = JSON.parse(localStorage.getItem("devicediscountobjectarray"));

                                for (let i = 0; i < devicepricing?.selectdiscount?.length; i++) {
                                    for (let k = 0; k < deviceselecteddiscounts.length; k++) {
                                        if (deviceselecteddiscounts[k] === devicepricing?.selectdiscount[i]?._id) {
                                            let obj = {
                                                name: devicepricing?.selectdiscount[i]?.discountname,
                                                amount: devicepricing?.selectdiscount[i]?.amount,
                                            };
                                            discounts.push(obj);
                                        }
                                    }
                                }
                                for (let i = 0; i < plandata?.length; i++) {
                                    if (object.plan === plandata[i]?._id) {
                                        planName = plandata[i]?.name;
                                        planCharges = plandata[i]?.price;

                                        planId = plandata[i]?._id;
                                    }
                                }
                                let deviceadditional = JSON.parse(localStorage.getItem("deviceadditionalfeaturearray"));
                                for (let k = 0; k < deviceadditional?.length; k++) {
                                    for (let i = 0; i < devicepricing?.additionalFeature?.length; i++) {
                                        if (deviceadditional[k] === devicepricing?.additionalFeature[i]?._id) {
                                            let obj = {
                                                name: devicepricing?.additionalFeature[i]?.featureName,
                                                amount: devicepricing?.additionalFeature[i]?.featureAmount,
                                            };
                                            additionalFeature.push(obj);
                                        }
                                    }
                                }
                            }
                            let plan = object?.plan;
                            // let dateincasepart
                            //object.totalAmount === paid ? dueDate:"Partial"
                            const loginRes = localStorage.getItem("userData");
                            let checkLabel;
                            try {
                                const storedItem = localStorage.getItem("checkLabel");
                                checkLabel = storedItem ? JSON.parse(storedItem) : null;
                            } catch (error) {
                                console.error("Error parsing checkLabel from localStorage", error);
                                checkLabel = null;
                            }

                            const parseLoginRes = JSON.parse(loginRes);
                            const shipmentName = localStorage.getItem("shipmentName");
                            const shipmentCost = localStorage.getItem("shipmentCost");
                            let dataToSend = {
                                invoiceType: "Sign Up",
                                totalAmount: object.totalamount,
                                additionalCharges: additionalFeature,
                                discount: discounts,
                                customerId: object.customerid,
                                amountPaid: paid ? paid : "0",
                                selectProduct: object?.billId,
                                invoiceDueDate: dueDate,
                                lateFee: applyLateFee,
                                invoiceOneTimeCharges: oneTimeCharge,
                                invoiceStatus: paid ? (parseFloat(object.totalamount) <= parseFloat(paid) ? "Paid" : "Partial") : "Unpaid",
                                planId: plan,
                                planName: planName,
                                planCharges: planCharges,
                                chargingType: "monthly",
                                invoicePaymentMethod: "skip",
                                printSetting: "Both",
                                isInvoice: true,

                                billingPeriod: {
                                    from: "onActivation",
                                    to: "onActivation",
                                },
                                isShipmentCostDiscounted: checkLabel,
                                shipmentCost: {
                                    name: shipmentName,
                                    amount: shipmentCost,
                                },
                                userId: parseLoginRes?._id,
                            };

                            localStorage.setItem("datasendforinvoice", JSON.stringify(dataToSend));
                            Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                                .then((response) => {
                                    localStorage.setItem("paymentallinfo", JSON.stringify(response.data));

                                    setActiveIndex(3);
                                })
                                .catch((err) => {});
                        }
                    } else if (formik.values.paymentMode === "E-Check (Authorize)") {
                        setAuthorizeEcheckPaymentDialog(true);
                    }
                }
            }
        },
    });
    useEffect(() => {
        formik.setFieldValue("totalamount", parseFloat(formik.values.totalamount)?.toFixed(2));
    }, [formik.values.totalamount]);
    useEffect(() => {
        if (paymentInfo) {
            setPaymentDialogVisibility(false);
            formik.setFieldValue("billId", paymentInfo.billId);
            formik.setFieldValue("plan", paymentInfo.plan);
            formik.setFieldValue("additionalFeature", paymentInfo.additionalFeature);
            formik.setFieldValue("discount", paymentInfo.discount);
            formik.setFieldValue("totalamount", paymentInfo.totalAmount);
        }
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error block">{formik.errors[name]}</small>;
    };
    function onInventorySelect(item) {
        setCurrentPlanSelect("");
        setCurrentSelect(item.label);
        localStorage.setItem("product", item.label);
        formik.setFieldValue("totalamount", 0);
        formik.setFieldValue("billId", item.value);
        let inventory;
        let inventoryType = JSON.parse(localStorage.getItem("inventoryType"));
        for (let i = 0; i < inventoryType.length; i++) {
            if (item.value === inventoryType[i].value) {
                inventory = inventoryType[i].label;
                localStorage.setItem("product", inventory);
                break;
            }
        }
        setInventory(inventory);
        if (inventory === "SIM") {
            formik.setFieldValue("discount", JSON.parse(localStorage.getItem("simdiscountobjectarray")));
            let oneTimeCharge = parseFloat(JSON.parse(localStorage.getItem("simpricing")).oneTimeCharge);
            let amountafteradditionalfeature = parseFloat(JSON.parse(localStorage.getItem("simadditionaltotal")));
            formik.setFieldValue("plan", "");
            let amountafterdiscount = (oneTimeCharge + amountafteradditionalfeature - parseFloat(JSON.parse(localStorage.getItem("totalsimdiscount")))).toString();

            formik.setFieldValue("additional", JSON.parse(localStorage.getItem("simadditionalfeaturearraytotal")).length > 0 ? JSON.parse(localStorage.getItem("simadditionalfeaturearraytotal")) : []);
            formik.setFieldValue("discounts", JSON.parse(localStorage.getItem("simdiscountobjectarraytotal")).length > 0 ? JSON.parse(localStorage.getItem("simdiscountobjectarraytotal")) : []);
            formik.setFieldValue("totalamount", amountafterdiscount);
            setPreviousPlanPrice(0);
        } else if (inventory === "WIRELESS DEVICE") {
            formik.setFieldValue("plan", "");
            formik.setFieldValue("discount", JSON.parse(localStorage.getItem("devicediscountobjectarray")));
            formik.setFieldValue("additional", JSON.parse(localStorage.getItem("deviceadditionalfeaturearraytotal")));

            let oneTimeCharge = JSON.parse(localStorage.getItem("devicepricing")).oneTimeCharge;
            let amountafteradditionalfeature = parseFloat(JSON.parse(localStorage.getItem("deviceadditionaltotal")));
            let amountafterdiscount = (parseFloat(oneTimeCharge) + amountafteradditionalfeature - parseFloat(JSON.parse(localStorage.getItem("totaldevicediscount")))).toString();
            formik.setFieldValue("totalamount", amountafterdiscount);
            formik.setFieldValue("discounts", JSON.parse(localStorage.getItem("devicediscountobjectarraytotal")).length > 0 ? JSON.parse(localStorage.getItem("devicediscountobjectarraytotal")) : []);
            setPreviousPlanPrice(0);
        }
    }
    useEffect(() => {
        if (!checkLabelVal) {
            setCarrierVal("");
            setServicesVal("");
            setDeliveryVal("");
            setChecked("");
            localStorage.removeItem("shipmentName");
            localStorage.removeItem("shipmentCost");
        }
        let data = localStorage.getItem("prepaidbasicData");
        let parseData = JSON.parse(data);
        const dataToSend = {
            customerId: parseData?.data?._id,
            carrierVal: !checkLabelVal ? "" : carrierVal,
            servicesVal: !checkLabelVal ? "" : servicesVal,
            deliveryVal: !checkLabelVal ? "" : deliveryVal,
            labelSubmissionval: checkLabelVal,
            shippingLabelDiscount: checked,
        };

        Axios.post(`${BASE_URL}/api/user/storeServices`, dataToSend)
            .then((res) => {})
            .catch((err) => {});
    }, [carrierVal, servicesVal, deliveryVal, checkLabelVal, checked]);
    useEffect(() => {    
          
        let cost = 0;
        rates.map((item) => {
            if (item?.serviceCode === servicesVal && item?.serviceName.includes("- Package")) {
                cost = item?.shipmentCost + item?.otherCost;
            }
        });    
          let currentamount=0;   
         if(formik.values.totalamount !== ""){
    currentamount = parseFloat(formik.values.totalamount); 
         }

            
        
        currentamount += cost;
        currentamount -= previousRate;

        formik.setFieldValue("totalamount", currentamount); 
        
            setPreviousRate(cost); 
    
        
    }, [checked, rates]);  
      useEffect(()=>{ 
         
      },[currentScreen])
    return (
        <form onSubmit={formik.handleSubmit}>
            <ToastContainer />
            <div>
                <div className="flex flex-wrap flex-row justify-content-around">
                    {currentScreen === 1 ? (
                        <div className="mt-2 w-full flex flex-wrap flex-row justify-content-around">
                            <h1 style={{ fontSize: "16px" }} className="h5 selectProduct mt-6">
                                SELECT PRODUCT
                            </h1>
                            {JSON.parse(localStorage.getItem("inventoryType"))?.map((item) => {
                                return (
                                    <div style={{ opacity: `${item.label === current ? "0.5" : ""}`, marginTop: "11rem" }} className="inventorySelect">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <RadioButton
                                                style={{ zIndex: "0" }}
                                                type="button"
                                                disabled={item?.label === current || paymentInfo}
                                                onClick={() => {
                                                    onInventorySelect(item);
                                                }}
                                            />
                                            <h1 className="w-full h4" style={{ marginLeft: "-20px", textTransform: "uppercase" }}>
                                                {capitalizeSentence(item?.label)}
                                            </h1>
                                        </div>

                                        <img className="simimg" src={`./${item.label}.jpg`} />
                                        {/* <button
                                            type="button"
                                            disabled={item?.label === current || paymentInfo}
                                            onClick={() => {
                                                onInventorySelect(item);
                                            }}
                                        >
                                            Select
                                        </button> */}
                                    </div>
                                );
                            })}
                        </div>
                    ) : undefined}
                    {currentScreen === 2 ? (
                        <div style={{ marginTop: "3.5rem" }} className=" w-full flex flex-wrap flex-row justify-content-evenly">
                            <h1 className="w-full selectplan "> SELECT PLAN</h1>
                            {JSON.parse(localStorage.getItem("planprices"))?.map((item) => {
                                let include = false;
                                if (inventory === "SIM") {
                                    let plans = JSON.parse(localStorage.getItem("simplan"));
                                    for (let i = 0; i < plans.length; i++) {
                                        if (plans[i].value === item?._id) {
                                            include = true;
                                            break;
                                        }
                                    }
                                } else if (inventory === "WIRELESS DEVICE") {
                                    let plans = JSON.parse(localStorage.getItem("deviceplan"));
                                    for (let i = 0; i < plans.length; i++) {
                                        if (plans[i].value === item?._id) {
                                            include = true;
                                            break;
                                        }
                                    }
                                }
                                return include ? (
                                    <>
                                        <div className="planSelect " style={{ opacity: `${item._id === currentPlanSelect ? "0.5" : ""} ` }}>
                                            <div className="planinfo">
                                                <h1 style={{ marginLeft: "15px", marginTop: "10px" }}>{capitalizeSentence(item?.name)}</h1>
                                                <h1 style={{ marginLeft: "15px" }}>No Hidden Fees / No Contracts</h1>
                                                <h1 style={{ marginLeft: "15px" }} className="planprice">
                                                    <span style={{ fontFamily: "Inter", fontSize: "38px", fontWeight: "600", marginTop: "1px" }}>$</span>
                                                    <span style={{ marginLeft: "36px" }}>{item?.price}</span>
                                                    <span style={{ fontWeight: "600", fontFamily: "Inter", fontSize: "18px", marginLeft: "10px" }}>Monthly</span>
                                                </h1>
                                                <button
                                                    type="button"
                                                    disabled={item?._id === currentPlanSelect || paymentInfo}
                                                    onClick={() => {
                                                        onPlanSelect(item);
                                                    }}
                                                >
                                                    GET STARTED
                                                </button>
                                            </div>

                                            <p className="voiceallowance">
                                                <Tickbtn />
                                                <span style={{ marginLeft: "6px" }}>
                                                    Voice Allowance {item?.voiceAllowance} <span>{item.voiceAllowanceUnit}</span>
                                                </span>
                                            </p>
                                            <p className="dataallowance">
                                                <Tickbtn />
                                                <span style={{ marginLeft: "10px" }}>
                                                    Data Allowance {item?.dataAllowance} <span>{item.dataAllowanceUnit}</span>
                                                </span>
                                            </p>
                                            <p className="textallowance">
                                                <Tickbtn />
                                                <span style={{ marginLeft: "10px" }}>
                                                    Text Allowance {item?.textAllowance} <span>{item.textAllowanceUnit}</span>
                                                </span>
                                            </p>
                                        </div>
                                    </>
                                ) : undefined;
                            })}
                        </div>
                    ) : undefined}
                    {currentScreen === 3 ? (
                        <div className="flex flex-wrap w-full justify-content-left">
                            <div className="flex flex-wrap w-full calendar_field">
                                <Checkbox
                                    checked={checkLabelVal}
                                    onChange={(e) => {
                                        setCheckLabelVal(e.checked);
                                        setGenerate(e.checked);
                                        localStorage.setItem("generatelabel", generate);
                                    }}
                                />
                                <p className="filed_label">&nbsp;&nbsp;Generate Label on Submission</p>
                            </div>
                            <div className="calendar_field">
                                <p className="field_label">
                                    Carrier <span style={{ color: "red" }}>*</span>
                                </p>
                                <Dropdown
                                    disabled={!checkLabelVal}
                                    value={carrierVal}
                                    id="name"
                                    onChange={(e) => {
                                        setCarrierVal(e.value);
                                        setExpectedCost(false);
                                    }}
                                    options={carrier}
                                    optionLabel="name"
                                    placeholder="Select Carrier"
                                    optionValue="code"
                                    className="w-full "
                                />
                            </div>
                            <div className="calendar_field">
                                <p className="field_label ml-2">
                                    Services <span style={{ color: "red" }}>*</span>
                                </p>

                                <Dropdown
                                    value={servicesVal}
                                    id="name"
                                    disabled={!checkLabelVal}
                                    onChange={(e) => {
                                        setServicesVal(e.value);
                                        setExpectedCost(false);
                                    }}
                                    options={services}
                                    optionLabel="name"
                                    optionValue="code"
                                    placeholder="Select Service"
                                    className="w-full ml-2"
                                />
                            </div>
                            <div className="calendar_field">
                                <p className="field_label ,l-3">
                                    Delivery Confirmation <span style={{ color: "red" }}>*</span>
                                </p>

                                {carrierVal.name === "FedEx" ? (
                                    <Dropdown
                                        disabled={!checkLabelVal}
                                        value={deliveryVal}
                                        onChange={(e) => {
                                            setDeliveryVal(e.value);
                                            setExpectedCost(false);
                                        }}
                                        options={deliveryFedEx}
                                        optionLabel="name"
                                        optionValue="name"
                                        placeholder="Select Delivery Option"
                                        className="w-full ml-3 "
                                    />
                                ) : (
                                    <Dropdown
                                        disabled={!checkLabelVal}
                                        value={deliveryVal}
                                        onChange={(e) => {
                                            setDeliveryVal(e.value);
                                            setExpectedCost(false);
                                        }}
                                        options={delivery}
                                        optionLabel="type"
                                        optionValue="type"
                                        placeholder="Select Delivery Option"
                                        className="w-full ml-3"
                                    />
                                )}
                            </div>
                            <div className="calendar_field">
                                <p className="field_label mt-4">
                                    Expected Cost :
                                    <span>
                                        {ratesLoading ? (
                                            <span>
                                                &nbsp; <i className="pi pi-spin pi-spinner" style={{ color: "black" }}></i>
                                            </span>
                                        ) : (
                                            rates?.map((item) => {
                                                try {
                                                    if (item?.serviceCode === servicesVal && item?.serviceName.includes("- Package")) {
                                                        let rate = item?.shipmentCost + item?.otherCost;
                                                        rate = rate?.toFixed(2);
                                                        localStorage.setItem("shipmentCost", rate);
                                                        localStorage.setItem("shipmentName", item?.serviceName);

                                                        return <span key={item._id}> $ {rate}</span>;
                                                    }
                                                } catch (e) {
                                                }
                                            })
                                        )}
                                    </span>
                                </p>
                            </div>
                            <div className="flex flex-wrap w-full calendar_field">
                                <Checkbox
                                    disabled={!checkLabelVal}
                                    checked={checked}
                                    onChange={(e) => {
                                        setChecked(e.checked);
                                    }}
                                />
                                <p style={{ opacity: !checkLabelVal ? "0.5" : "" }} className="filed_label">
                                    &nbsp;&nbsp;Add Shipping Label as Discount
                                </p>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {currentScreen === 4 ? (
                        <div className="w-full flex flex-wrap flex-row justify-content-left prospdiv">
                            <div className="flex w-full flex-wrap flex-row justify-content-left  mt-6 ">
                                <Button
                                    type="button"
                                    className={`btn ${propectWithInvoice ? "prospectactive" : ""}`}
                                    disabled={(labelSubmissionval && checkLabelVal) || checkLabelVal}
                                    onClick={() => {
                                        formik.setFieldValue("paymentMode", "");
                                        formik.setFieldValue("prospectwithoutinvoice", false);
                                        setpaymentmethoderror(false);
                                        if (propectWithInvoice) {
                                            setProspectWithInvoice(false);

                                            formik.setFieldValue("prospectwithinvoice", false);
                                        } else {
                                            setProspectWithInvoice(true);
                                            formik.setFieldValue("paid", "");
                                            formik.setFieldValue("prospectwithinvoice", true);
                                        }
                                        setProspectWithOutInvoice(false);
                                        setActiveWithInvoice(true);
                                        setActiveWithoutInvoice(false);
                                    }}
                                    style={{ opacity: activeWithInvoice ? "0.5" : "", border: " 1px solid #0475b4", borderRadius: "8px", fontWeight: "600", fontSize: "14px", fontFamily: "Inter" }}
                                >
                                    Save As Prospect With Invoice
                                </Button>

                                <Button
                                    type="button"
                                    disabled={(labelSubmissionval && checkLabelVal) || checkLabelVal}
                                    onClick={() => {
                                        formik.setFieldValue("paymentMode", "");
                                        setpaymentmethoderror(false);

                                        formik.setFieldValue("prospectwithinvoice", false);
                                        if (propectWithOutInvoice) {
                                            setProspectWithOutInvoice(false);

                                            formik.setFieldValue("prospectwithoutinvoice", false);
                                        } else {
                                            setProspectWithOutInvoice(true);
                                            formik.setFieldValue("paid", "");
                                            formik.setFieldValue("prospectwithoutinvoice", true);
                                        }
                                        setProspectWithInvoice(false);
                                        setActiveWithoutInvoice(true);
                                        setActiveWithInvoice(false);
                                    }}
                                    className={`btn ${propectWithOutInvoice ? "prospectactive" : ""} `}
                                    style={{ opacity: activeWithoutInvoice ? "0.5" : "", border: " 1px solid #0475b4", borderRadius: "8px", fontWeight: "600", fontSize: "14px", fontFamily: "Inter", marginLeft: "10px" }}
                                >
                                    Save As Prospect WithOut Invoice
                                </Button>
                            </div>

                            <div className="mt-2  calendar_field">
                                <label className="field_label">Select Additional Feature</label>
                                {inventory === "SIM" ? (
                                    <>
                                        <MultiSelect
                                            disabled={paymentInfo ? true : false}
                                            className="w-full mt-2  discountmultiselect .p-multiselect-trigger "
                                            id="additional"
                                            placeholder="Select Additional Feature"
                                            optionLabel="name"
                                            options={JSON.parse(localStorage.getItem("simadditional"))}
                                            value={formik.values.additional}
                                            onChange={(e) => {
                                                let additional = formik.values.additional;
                                                let prerviousadditionaltotal = 0;
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                let simpricing = JSON.parse(localStorage.getItem("simpricing"));
                                                for (let i = 0; i < additional.length; i++) {
                                                    for (let k = 0; k < simpricing.additionalFeature.length; k++) {
                                                        if (simpricing.additionalFeature[k]._id === additional[i]) {
                                                            prerviousadditionaltotal += parseFloat(simpricing.additionalFeature[k].featureAmount);
                                                        }
                                                    }
                                                }
                                                totalamount -= prerviousadditionaltotal;
                                                let additionalnew = e.value;
                                                let newadditionaltotal = 0;
                                                for (let i = 0; i < additionalnew.length; i++) {
                                                    for (let k = 0; k < simpricing.additionalFeature.length; k++) {
                                                        if (simpricing.additionalFeature[k]._id === additionalnew[i]) {
                                                            newadditionaltotal += parseFloat(simpricing.additionalFeature[k].featureAmount);
                                                        }
                                                    }
                                                }
                                                totalamount += newadditionaltotal;
                                                localStorage.setItem("simadditionalfeaturearray", JSON.stringify(e.value));
                                                formik.setFieldValue("additional", e.value);
                                                formik.setFieldValue("totalamount", totalamount.toString());
                                            }}
                                        />
                                        {getFormErrorMessage("additional")}
                                    </>
                                ) : (
                                    <>
                                        <MultiSelect
                                            disabled={paymentInfo ? true : false}
                                            className="w-full mt-2 discountmultiselect .p-multiselect-trigger"
                                            placeholder="Select Additional Feature"
                                            id="additional"
                                            optionLabel="name"
                                            options={JSON.parse(localStorage.getItem("deviceadditional"))}
                                            value={formik.values.additional}
                                            onChange={(e) => {
                                                let additional = formik.values.additional;
                                                let prerviousadditionaltotal = 0;
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                let devicepricing = JSON.parse(localStorage.getItem("devicepricing"));
                                                for (let i = 0; i < additional.length; i++) {
                                                    for (let k = 0; k < devicepricing.additionalFeature.length; k++) {
                                                        if (devicepricing.additionalFeature[k]._id === additional[i]) {
                                                            prerviousadditionaltotal += parseFloat(devicepricing.additionalFeature[k].featureAmount);
                                                        }
                                                    }
                                                }
                                                totalamount -= prerviousadditionaltotal;
                                                let additionalnew = e.value;
                                                let newadditionaltotal = 0;
                                                for (let i = 0; i < additionalnew.length; i++) {
                                                    for (let k = 0; k < devicepricing.additionalFeature.length; k++) {
                                                        if (devicepricing.additionalFeature[k]._id === additionalnew[i]) {
                                                            newadditionaltotal += parseFloat(devicepricing.additionalFeature[k].featureAmount);
                                                        }
                                                    }
                                                }
                                                totalamount += newadditionaltotal;
                                                localStorage.setItem("deviceadditionalfeaturearray", JSON.stringify(e.value));
                                                formik.setFieldValue("additional", e.value);
                                                formik.setFieldValue("totalamount", totalamount.toString());
                                            }}
                                        />
                                        {getFormErrorMessage("additional")}
                                    </>
                                )}
                            </div>

                            <div style={{ marginLeft: "4px" }} className="mt-2  calendar_field">
                                <label className="field_label">Select Discounts</label>
                                {inventory === "SIM" ? (
                                    <>
                                        <MultiSelect
                                            disabled={paymentInfo ? true : false}
                                            className="w-full mt-2 discountmultiselect .p-multiselect-trigger"
                                            id="discount"
                                            placeholder="Select Discounts"
                                            optionLabel="discountname"
                                            optionValue="_id"
                                            options={JSON.parse(localStorage.getItem("simdiscount"))}
                                            value={formik.values.discounts}
                                            onChange={(e) => {
                                                let discount = formik.values.discounts;
                                                let prerviousdiscounttotal = 0;
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                let discountpricing = JSON.parse(localStorage.getItem("simdiscount"));
                                                for (let i = 0; i < discount.length; i++) {
                                                    for (let k = 0; k < discountpricing.length; k++) {
                                                        if (discount[i] === discountpricing[k]._id) {
                                                            prerviousdiscounttotal += parseFloat(discountpricing[k].amount);
                                                        }
                                                    }
                                                }
                                                totalamount += prerviousdiscounttotal;
                                                let discountnew = e.value;
                                                let newdiscounttotal = 0;
                                                for (let i = 0; i < discountnew.length; i++) {
                                                    for (let k = 0; k < discountpricing.length; k++) {
                                                        if (discountnew[i] === discountpricing[k]._id) {
                                                            newdiscounttotal += parseFloat(discountpricing[k].amount);
                                                        }
                                                    }
                                                }
                                                totalamount -= newdiscounttotal;
                                                localStorage.setItem("simdiscountobjectarray", JSON.stringify(e.value));
                                                formik.setFieldValue("discounts", e.value);
                                                formik.setFieldValue("totalamount", totalamount.toString());
                                            }}
                                        />
                                        {getFormErrorMessage("discounts")}
                                    </>
                                ) : (
                                    <>
                                        <MultiSelect
                                            disabled={paymentInfo ? true : false}
                                            className="w-full mt-2 discountmultiselect .p-multiselect-trigger"
                                            placeholder="Select Discounts"
                                            id="discount"
                                            optionLabel="discountname"
                                            optionValue="_id"
                                            options={JSON.parse(localStorage.getItem("devicediscount"))}
                                            value={formik.values.discounts}
                                            onChange={(e) => {
                                                let discount = formik.values.discounts;
                                                let prerviousdiscounttotal = 0;
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                let discountpricing = JSON.parse(localStorage.getItem("devicediscount"));
                                                for (let i = 0; i < discount.length; i++) {
                                                    for (let k = 0; k < discountpricing.length; k++) {
                                                        if (discount[i] === discountpricing[k]._id) {
                                                            prerviousdiscounttotal += parseFloat(discountpricing[k].amount);
                                                        }
                                                    }
                                                }
                                                totalamount += prerviousdiscounttotal;
                                                let discountnew = e.value;
                                                let newdiscounttotal = 0;
                                                for (let i = 0; i < discountnew.length; i++) {
                                                    for (let k = 0; k < discountpricing.length; k++) {
                                                        if (discountnew[i] === discountpricing[k]._id) {
                                                            newdiscounttotal += parseFloat(discountpricing[k].amount);
                                                        }
                                                    }
                                                }
                                                totalamount -= newdiscounttotal;
                                                localStorage.setItem("devicediscountobjectarray", JSON.stringify(e.value));
                                                formik.setFieldValue("discounts", e.value);
                                                formik.setFieldValue("totalamount", totalamount.toString());
                                            }}
                                        />
                                        {getFormErrorMessage("discounts")}
                                    </>
                                )}
                            </div>
                            <div style={{ marginLeft: "4px" }} className="mt-2 calendar_field">
                                <label className="field_label">Net Amount</label>
                                <InputText
                                    disabled
                                    className="w-full mt-2"
                                    id="totalamount"
                                    value={formik.values.totalamount}
                                    onChange={(e) => {
                                        formik.setFieldValue("totalpayment", e.value);
                                        formik.handleChange(e);
                                    }}
                                />
                                {getFormErrorMessage("totalpayment")}
                            </div>
                            {/* if (toCapitalCompanyName.toUpperCase() === "ZISFONE LLC") { */}
                            {formik.values.paymentMode === "Credit Card" ? (
                                <div className="mt-6 calendar_field">
                                    <label className="field_label">Paying Amount</label>
                                    <InputText
                                        disabled={paymentInfo ? true : false}
                                        className="w-full mt-2 "
                                        id="paid"
                                        value={formik.values.paid}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setPaidAmountRequired(true);
                                            } else {
                                                setPaidAmountRequired(false);
                                            }
                                            formik.setFieldValue("paid", e.target.value);
                                            // formik.handleChange(e);
                                        }}
                                    />
                                    {paidAmountRequired ? <p className="p-error mt-1 ml-1">Paying Amount Is Required</p> : ""}
                                    {getFormErrorMessage("paid")}
                                </div>
                            ) : (
                                ""
                            )}

                            <div style={{ marginLeft: "4px" }} className="mt-6 calendar_field">
                                <label className="field_label">Select Payment Method</label>
                                <Dropdown
                                    disabled={paymentInfo ? true : false || activeWithInvoice || activeWithoutInvoice}
                                    className="w-full mt-2 p-dropdown .p-dropdown-trigger"
                                    id="paymentMode"
                                    optionLabel="name"
                                    optionValue="name"
                                    options={inventory === "SIM" ? JSON.parse(localStorage.getItem("simPaymentMethod")) : inventory === "WIRELESS DEVICE" ? JSON.parse(localStorage.getItem("devicePaymentMethod")) : []}
                                    value={formik.values.paymentMode}
                                    onChange={(e) => {
                                        formik.setFieldValue("paymentMode", e.value);
                                        formik.handleChange(e);
                                        setpaymentmethoderror(false);
                                        setProspectWithOutInvoice(false);
                                        formik.setFieldValue("prospectwithoutinvoice", false);

                                        formik.setFieldValue("prospectwithinvoice", false);
                                        setProspectWithInvoice(false);
                                        /* if (e.value === "card") {
                                        setPaymentDialogVisibility(true);
                                    }*/
                                    }}
                                />
                                {paymentmethoderror && <p className="p-error">Payment Method Is Required</p>}
                            </div>
                            <div className=" flex flex-wrap mt-6 w-full">
                                {checkLabelVal && !checked ? <p style={{ fontWeight: "500" }}>The cost of shipment has been added to the net payment and is payable by the customer.</p> : ""}
                                {checkLabelVal && checked ? <p style={{ fontWeight: "500" }}>The cost of shipment has not been added to the net payment. Shipment cost will be covered by the company</p> : ""}
                            </div>
                        </div>
                    ) : undefined}
                    {formik.values.paymentMode == "Credit Card" && !(localStorage.getItem("paymentstatus") === "paid") ? (
                        <>
                            <Dialog style={{ textAlign: "center" }} className="stripe-dialog-width" header="Stripe Payment" visible={paymentDialogVisibility} setPaymentDialogVisibility={setPaymentDialogVisibility} onHide={() => setPaymentDialogVisibility(false)}>
                                <PaymentStripModule paid={formik.values.paid} plan={formik.values.plan} setPaymentDialogVisibility={setPaymentDialogVisibility} amount={formik.values.totalamount} object={formik.values} _id={_id} setActiveIndex={setActiveIndex} />
                            </Dialog>
                        </>
                    ) : undefined}
                </div>
            </div>
            <div className=" flex justify-content-right mt-8 ">
                <Button
                    style={{ marginLeft: "80%" }}
                    className="btn"
                    label="Back"
                    type="button"
                    onClick={() => {
                        if (currentScreen !== 1) {
                            setCurrentScreen((prev) => (prev = prev - 1));
                        } else {
                            setActiveIndex(1);
                        }
                    }}
                />
                <Button
                    className="btn ml-2"
                    label="Continue"
                    type={currentScreen < 5 ? "button" : "submit"}
                    icon={isLoading ? "pi pi-spinner" : ""}
                    disabled={
                        (current === "" && currentScreen === 1) ||
                        (currentPlanSelect === "" && currentScreen === 2) ||
                        (currentScreen === 3 && checkLabelVal && ((carrierVal === "" && checkLabelVal) || !expectedCost || (servicesVal === "" && checkLabelVal) || !expectedCost || (deliveryVal === "" && checkLabelVal) || !expectedCost))
                    }
                    onClick={() => {
                        if (currentScreen !== 4) {
                            if (currentScreen === 3) {
                                if (!checked && !checkLabelVal) {    
                                    let currentamount = parseFloat(formik.values.totalamount);
                                    currentamount -= previousRate;
                                    setPreviousRate(0);
                                    formik.setFieldValue("totalamount", currentamount);
                                } else if (checked && checkLabelVal) {
                                    let currentamount = parseFloat(formik.values.totalamount);
                                    currentamount -= previousRate;
                                    setPreviousRate(0);
                                    formik.setFieldValue("totalamount", currentamount);
                                }  
                        
                            }
                            setCurrentScreen((prev) => (prev = prev + 1));
                        } else {
                            formik.handleSubmit();
                        }
                    }}
                />
            </div>
            <Dialog
                visible={authorizePaymentDialog}
                onHide={() => {
                    setAuthorizePaymentDialog(false);
                }}
                header="Card Payment"
            >
                <CardAuthPayment formik2={formik} paid={formik.values.paid} plan={formik.values.plan} setPaymentDialogVisibility={setPaymentDialogVisibility} amount={formik.values.totalamount} object={formik.values} setActiveIndex={setActiveIndex} />
            </Dialog>
            <Dialog
                visible={authorizeEcheckPaymentDialog}
                onHide={() => {
                    setAuthorizeEcheckPaymentDialog(false);
                }}
                header="Autorize E Check Payment"
            >
                <EcheckAuthPayment formik2={formik} paid={formik.values.paid} plan={formik.values.plan} setPaymentDialogVisibility={setPaymentDialogVisibility} amount={formik.values.totalamount} object={formik.values} setActiveIndex={setActiveIndex} />
            </Dialog>
            <Dialog
                visible={authorizeEcheckPaymentDialog}
                onHide={() => {
                    setAuthorizeEcheckPaymentDialog(false);
                }}
                header="Autorize E Check Payment"
            >
                <EcheckAuthPayment formik2={formik} paid={formik.values.paid} plan={formik.values.plan} setPaymentDialogVisibility={setPaymentDialogVisibility} amount={formik.values.totalamount} object={formik.values} setActiveIndex={setActiveIndex} />
            </Dialog>
        </form>
    );
};

export default PaymentScreen;
