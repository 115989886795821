import React, { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Axios from "axios";
import { Toast } from "primereact/toast";
import CommonLoader from "../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/Loader";
import CommonLoaderBlue from "../../../Prepaid_Updated/Add_Service/components/PaymentScreen/dialog/loader/LoaderBlue";
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const BASE_URL = process.env.REACT_APP_BASE_URL;
const CheckoutForm = ({ paid, setPaymentDialogVisibility, userDetails, setRefresh, setOpenDialogeForWallet }) => {
    let toast = useRef();
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }
    const [alternateCardToken, setAlternateCardToken] = useState("");
    const [alternateCardDetailVisibility, setAlternateCardDetailVisibility] = useState(false);
    const [autoPay, setAutoPay] = useState(true);
    const parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    const [clientSecret, setClientSecret] = useState("");
    const [elements, setElements] = useState(null);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch the client secret as soon as the page loads
        initialize();
        //heckStatus();
    }, []);
    const formStyle = {
        fontFamily: '"Roboto", sans-serif', // Example font-family
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        fontWeight: "500",
    };

    const buttonStyle = {
        fontFamily: '"Roboto", sans-serif',
        backgroundColor: "#5469d4",
        color: "#ffffff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
        marginTop: "10px",
    };
    const initialize = async () => {
        let response = await Axios.post(`${BASE_URL}/api/web/billing/paymentintent`, { amount: paid });
        const clientSecret = await response.data.clientSecret;
        setClientSecret(clientSecret);

        const appearance = {
            theme: "stripe",
            variables: {
                fontWeightMedium: "600",
                fontWeightNormal: "600",
            },
            rules: {
                ".Input:focus": {
                    borderColor: "#e6e6e6",
                    border: "1px solid #e6e6e6 ",
                    boxShadow: "none !important",
                },
            },
        };
        const stripe = await stripePromise;
        const elementsInstance = stripe.elements({ appearance, clientSecret });
        setElements(elementsInstance);

        const paymentElementOptions = {
            layout: "tabs",
        };

        const paymentElement = elementsInstance.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const stripe = await stripePromise;
        const { paymentIntent, error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
            },
            redirect: "if_required",
        });
        if (error) {
            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
                customerId: userDetails?._id,
                paymentMethod: "Credit Card",
                stripeId: paymentIntent?.id,
                invoiceType: "Sign Up",
                action: "failure",
                invoiceNo: [],
                reason: "failure",
                cardNumber: error?.payment_method?.card?.last4,
                transactionStatus: error?.message,
                paymentStatus: "failure",
                amount: paid,
                userId: parseLoginRes?._id,
            })
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                });
            toast.current.show({ severity: "error", summary: "Payment Processing Error", detail: "An error occurred while processing the payment" });

            setTimeout(() => {
                setPaymentDialogVisibility(false);
            }, 1000);
        } else {
            toast.current.show({ severity: "success", summary: "Payment Processed", detail: "Payment has been successfully processed" });
            const dataToSend = {
                amount: paid,
                userId: parseLoginRes?._id,
                customerId: userDetails?._id,
                serviceProvider: parseLoginRes?.company,
                paymentIntentId: paymentIntent?.id,
            };

            Axios.post(`${BASE_URL}/api/web/invoices/addinWallet`, dataToSend)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setTimeout(() => {
                            setOpenDialogeForWallet(false);
                        }, 2000);
                        setRefresh((prev) => !prev);
                        toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                });
        }
    };

    const checkStatus = async () => {
        const clientSecretFromURL = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

        if (!clientSecretFromURL) {
            return;
        }

        const stripe = await stripePromise;
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecretFromURL);

        switch (paymentIntent.status) {
            case "succeeded":
                showMessage("Payment succeeded!");
                break;
            case "processing":
                showMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                showMessage("Your payment was not successful, please try again.");
                break;
            default:
                showMessage("Something went wrong.");
                break;
        }
    };

    const showMessage = (messageText) => {
        setMessage(messageText);
        setTimeout(() => setMessage(""), 4000);
    };

    const setLoading = (isLoading) => {
        setIsLoading(isLoading);
    };

    return (
        <div>
            <Toast ref={toast} />
            {!clientSecret ? (
                <div className="flex flex-wrap flex-row justify-content-center align-items-center mt-4 mb-4">
                    <CommonLoaderBlue />
                </div>
            ) : undefined}
            <form id="payment-form" onSubmit={handleSubmit} style={formStyle}>
                <div id="payment-element"></div>
                <button id="submit" style={buttonStyle} disabled={!clientSecret || isLoading}>
                    <span id="button-text">
                        {isLoading ? (
                            <div className="w-full flex flex-wrap flex-row justify-center align-items-center">
                                <CommonLoader />
                            </div>
                        ) : (
                            "Pay now"
                        )}
                    </span>
                    <div id="spinner" className={isLoading ? "" : "hidden"}></div>
                </button>
                {message && <div id="payment-message">{message}</div>}
            </form>
        </div>
    );
};

const Payment = ({
    parentDetails,
    initiationData,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    clientSecret,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,
    userDetails,
    setRefresh,
    setOpenDialogeForWallet,
}) => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm
                setAllPaymentInfo={setAllPaymentInfo}
                parentDetails={parentDetails}
                setPreviewScreenActive={setPreviewScreenActive}
                setPaymentScreenActive={setPaymentScreenActive}
                setDataSendForInvoice={setDataSendForInvoice}
                paymentScreenDetails={paymentScreenDetails}
                currentBillingConfig={currentBillingConfig}
                paid={paid}
                plan={plan}
                paymentDone={paymentDone}
                setPaymentDone={setPaymentDone}
                setPaymentScreenDetails={setPaymentScreenDetails}
                setPaymentDialogVisibility={setPaymentDialogVisibility}
                initiationData={initiationData}
                object={object}
                userDetails={userDetails}
                setRefresh={setRefresh}
                setOpenDialogeForWallet={setOpenDialogeForWallet}
            />
        </Elements>
    );
};

export default Payment;
